import React from 'react';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import {currentLocale} from "../../i18n";

const Imprint = () => {
    return (
        <div>
            <Navbar locale={currentLocale()} currentPage="privacy-policy">

            </Navbar>
            <div className="has-static-nav">
                <div className="container">
                    <div className="bg bg-small">
                        <h1 className="title">Impressum</h1>

                        <p>adesso SE<br/>
                            Adessoplatz 1<br/>
                            44269 Dortmund<br/>
                            T +49 231 7000-7000<br/>
                            F +49 231 7000-1000<br/>
                            E info@adesso.de</p>
                        <p>Vertreten durch den Vorstand:<br/>
                            Michael Kenfenheuer (Vors.), Christoph Junge, Dirk Pothen, Andreas Prenneis.</p>
                        <p>Aufsichtsratsvorsitzender: Prof. Dr. Volker Gruhn</p>
                        <p>Handelsregister beim Amtsgericht Dortmund, Nr. HRB 20663<br/>
                            Umsatzsteueridentifikationsnummer DE 208 318 527<br/></p>
                        <p>Verantwortlich im Sinne des § 55 Abs. 2 RStV: </p>
                        <p>Rüdiger Striemer (Chief Marketing Officer) <br/>
                            Rotherstr. 19<br/>
                            10245 Berlin<br/>
                            E office-berlin@adesso.de</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default () => {
    return (<Imprint />);
};
